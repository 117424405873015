/* App.css */
.App {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  nav {
    margin-bottom: 20px;
  }
  
  nav a {
    margin: 0 10px;
    text-decoration: none;
    color: #007bff;
  }
  
  nav a:hover {
    text-decoration: underline;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    margin-bottom: 10px;
  }
  
  form input, form textarea, form select {
    padding: 8px;
    margin: 5px 0;
    width: 300px;
  }
  
  button {
    padding: 10px;
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  h2 {
    color: #333;
  }
  